<template>
  <b-card title=" Edit Product Hararqy">
    <b-row>
      <b-col cols="12">
        <b-form @submit.prevent="">
          <b-form-group label="Product Hierarchy Number (First 5 digits)">
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="phNumber"
                  type="text"
                  placeholder="Enter PH Number (First 5 digits)"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Word" >
            <b-row>
              <b-col cols="12" md="8">
                <b-form-input
                  v-model="word"
                  type="text"
                  placeholder="Enter Word"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          
          <b-btn @click="save" variant="success">
            Save
          </b-btn>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from '@/axios';
import { sleep, pushTo }from '@/utils/utils.js'
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      phNumber: '',
      word: '',
      items: [],
      selectedItem: null,
      nonSaleItems: [],
      status : "false",
      plant : 1000,
      description:'-'
    }
  },
  mounted() {
    this.getPostalAndItems();
    // this.getItems({status: "Active"});
  },
  computed: {
    // ...mapState({
    //   itemCatalogue: (state) => {
    //     return state.item.items.map((i) => {
    //       return { label: `${i.description} - ${i.itemModel} `, ...i }
    //     });
    //   }
    // })
  },
  methods: {
    ...mapActions({
      getItems: 'item/getItems',
    }),
    getPostalAndItems() {
      axios.get(`sales-and-purchase/drcc/phmap/${this.$route.params.id}`).then((response) => {
        let { phNumber, word } = response.data.data;
        this.phNumber = phNumber;
        this.word = word;
      }).catch((e) => {
        console.log(e)
      })
    },
    save() {


      //console.log('itemModel edit',itemModel)
      axios.put(`sales-and-purchase/drcc/phmap/${this.$route.params.id}`, 
      { phNumber: this.phNumber, 
         word:this.word, 
      })
      .then(async (response) => {
        this.$bvToast.toast("Successfully Edit POS Code", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({ name: "master-ph" });
      }).catch((e) => {
        console.log(e)
        this.$bvToast.toast('Failed to Edit to POS Code', {
          title: "Success",
          variant: "danger",
          solid: true,
        });
      })
      this.$router.push({ name: "" });
    }
  }
}
</script>
<style scoped>
@media (max-width: 761px) {
  .hide-on-desktop { display: none !important; }
  .show-on-desktop {display: inline !important;}
}

@media (min-width: 762px) {
  .hide-on-mobile { display: none !important; }
}
</style>